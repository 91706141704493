import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import profile from "../images/profile@2x.png"
import mail from "../images/mail.svg"

function AboutPage() {
  const lang = "en";
  return (
    <Layout lang={lang}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="About"
      />

      <section className="bg-blue-600 text-white py-4">
          <div className="max-w-3xl mx-auto">
            <div className="mb-8">
              <h2>About</h2>
              <div className="text-xl text-blue-100">
                <p className="font-light">Paperplane Pages was created because nobody should wait for reaching their goals.</p>
                <p className="font-light">The Internet lives fast. Users have short attention spans. They leave sites, if the experience does not run smoothly.</p>
                <p className="font-light">Paperplane Pages tries to solve this problem. With faster sites & individual content.</p>
              </div>
            </div>
            <div className="mb-8">
              <h2>Team</h2>
              <div className="md:flex md:flex-row md:items-center max-w-lg card bg-blue-100 text-blue-900 border-blue-300">
                <img className="h-32 w-32 mb-4 md:mb-0" src={profile} alt=""/>
                <div className="md:ml-4">
                  <div className="text-blue-400 label">Founder & Developer</div>
                  <h3 className="text-2xl">Matthias Diebels</h3>
                  <a className="text-blue-700 font-bold text-base flex items-start" href="#">
                    <img src={mail} alt=""/>  info@paperplanepages.com
                  </a>
                </div>
              </div>
            </div>
            <h2>Contact</h2>
            <div className="card bg-blue-100 text-blue-900 border-blue-300">
              <div className="font-bold">You want to chat?</div>
              <div className="text-blue-700 mb-4">Leave your Email and lets talk.</div>
              <form name="about" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="about" />
                <input placeholder="Email" className="px-4 py-2 shadow bg-white border-blue-300 border rounded-lg w-full mb-4 text-blue-600 placeholder-blue-300" type="text" name="email"/>
                <button className="px-4 py-2 bg-blue-700 rounded-lg text-white shadow-md w-full" type="submit">Send</button>
              </form>
            </div>
          </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
